@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Instrument+Serif&display=swap');

@layer base {
  :root {

    --background-color: white;

  }
}

.mx-auto {
  margin-left: auto;
  margin-right: auto
}

.mt-0 {
  margin-top: 0
}

.-mt-20 {
  margin-top: -5rem
}

.mb-20 {
  margin-bottom: 5rem
}

.mt-10 {
  margin-top: 2.5rem
}

.flex {
  display: flex
}

.min-h-\[150px\] {
  min-height: 150px
}

.w-full {
  width: 100%
}

.w-3\/4 {
  width: 75%
}

.flex-row {
  flex-direction: row
}

.flex-col {
  flex-direction: column
}

.items-center {
  align-items: center
}

.gap-12 {
  gap: 3rem
}

.gap-6 {
  gap: 1.5rem
}

.gap-4 {
  gap: 1rem
}

.border-b-2 {
  border-bottom-width: 2px
}

.border-yellow-200 {
  --tw-border-opacity: 1;
  border-color: rgb(254 240 138/var(--tw-border-opacity))
}

.pb-2 {
  padding-bottom: .5rem
}

.font-satoshib {
  font-family: Satoshi-Bold,sans-serif
}

.font-satoshim {
  font-family: Satoshi-Medium,sans-serif
}

.font-satoshir {
  font-family: Satoshi-Regular,sans-serif
}

.text-3xl {
  font-size: 3rem;
  line-height: 2rem
}

.text-2halfxl {
  font-size: 2rem;
  line-height: 2rem
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem
}

.font-bold {
  font-weight: 700
}

.uppercase {
  text-transform: uppercase
}

.leading-none {
  line-height: 1
}

.tracking-widest {
  letter-spacing: .25em
}

.text-yellow-200 {
  --tw-text-opacity: 1;
  color: rgb(254 240 138/var(--tw-text-opacity))
}

.text-white-200 {
  --tw-text-opacity: 1;
  color: rgb(229 229 207/var(--tw-text-opacity))
}

.text-stone-700 {
  --tw-text-opacity: 1;
  color: rgb(68 64 60/var(--tw-text-opacity))
}

.opacity-100 {
  opacity: 1
}

.opacity-50 {
  opacity: .5
}

.transition-all {
  /* transition-duration: 0.3s;
  transition-property: all;
  transition-timing-function: ease-in-out; */
  transition-duration: .3s;
  transition-property: all;
  transition-timing-function: cubic-bezier(.4,0,.2,1)
}

/* a.email:hover {
background-color: rgb(254,240,138)
} */

a.email {
  position: relative;
  text-decoration: none;
}

a.email::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: rgb(254, 240, 138);
  transition: width 0.1s ease-in-out;
  z-index: -1;
}

a.email:hover::before {
  width: 100%;
}

a.title,.highlight {
  position: relative;
  text-decoration: none;
}

a.title::before,.highlight::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: rgb(254, 240, 138);
  transition: width 0.1s ease-in-out;
  z-index: -1;
}

a.title:hover::before,.highlight:hover::before {  
  width: 100%;
}



@font-face {
  font-family: "Satoshi-Medium";
  src: url("../fonts/Satoshi/Satoshi-Medium.ttf");
}
@font-face {
  font-family: "Satoshi-Bold";
  src: url("../fonts/Satoshi/Satoshi-Bold.ttf");
}
@font-face {
  font-family: "Satoshi-Regular";
  src: url("../fonts/Satoshi/Satoshi-Regular.ttf");
}

.font-professional {
  font-family: 'Satoshi', sans-serif;
  color: black;
}

.font-creative {
  font-family: 'Instrument Serif', serif;
}

.font-instrument-serif {
  font-family: 'Instrument Serif';
  letter-spacing: .07em;
  font-size: medium;
  font-style: italic;

}

.font-instrument-serifb {
  font-family: 'Instrument Serif';
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-color);
  color: #e5e5cf;
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
  margin: 0;
  transition: background-color 0.3s ease, color 0.3s ease;
}

code {
  font-family: source-code-pro,Menlo,Monaco,Consolas,Courier New,monospace
}

.active\:text-xs:active {
  font-size: .75rem;
  line-height: 1rem
}

.theme-professional {
  --background-color: white;
}

.theme-creative {
  --background-color: black;
}


@media (min-width: 768px) {
  .md\:mt-20 {
      margin-top:5rem
  }

  .md\:w-4\/5 {
      width: 80%
  }

  .md\:w-3\/4 {
      width: 75%
  }

  .md\:w-3\/6 {
      width: 50%
  }

  .md\:gap-8 {
      gap: 2rem
  }

}

@media (min-width: 1024px) {
  .lg\:-mt-48 {
      margin-top:-12rem
  }
}

@media (max-width: 768px) {
  a.title::before,.highlight::before {
    width: 100%; /* Keep the background visible */
    transition: none; /* Disable transition for instant effect */
  }
}
